import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import mapValues from 'lodash/mapValues'
import keyBy from 'lodash/keyBy'

import Layout from '../../components/Layout'
import { trackEvent } from '../../utils/segmentTrack'
import Text from '../../components/common/Text'
import { calendlyAPIKey } from '../../../config/keys'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 90px;
  margin-top: 80px;

  @media (max-width: 800px) {
    margin-top: 40px;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  min-height: 40vh;
  height: 100%;
  flex-direction: column;
  padding-bottom: 90px;
  justify-content: center;
`

const Header = styled.div`
  padding: 0px 80px;
  padding-bottom: 32px;

  @media (max-width: 800px) {
    padding: 0px 0px;
    padding-bottom: 48px;
  }
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;

  p {
    max-width: 700px;

    @media (max-width: 800px) {
      margin-bottom: 32px;
    }
  }
`

const ScheduleMeeting = () => {
  const [location, setLocation] = useState(null)
  const [calendlyID, setCalendlyID] = useState('aesales/enterprise-demo')

  const hsFormData = typeof window !== 'undefined' && window.sessionStorage.getItem('hs-form-data')
  const parsedData = JSON.parse(hsFormData)
  const mappedData = mapValues(keyBy(parsedData, 'name'), 'value')

  if (typeof window !== 'undefined') {
    const script = document.createElement('script')
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.async = true
    document.body.appendChild(script)
  }

  useEffect(() => {
    const usrLang = navigator.languages
    if (usrLang) {
      // eslint-disable-next-line no-unused-expressions
      usrLang.includes('en-US') ? setLocation('national') : setLocation('international')
    } else {
      setLocation('national')
    }
    if (location === 'international') {
      setCalendlyID('international-enterprise-sales/enterprise-demo')
    }
  }, [location])

  const modifiedCalendlyID =
    mappedData.agency_or_freelancer === 'Yes' ? 'agency-sdr/agency-freelancer-partner-demo' : calendlyID

  const url = `https://calendly.com/${modifiedCalendlyID}?name=${mappedData.firstname || ''} ${
    mappedData.lastname || ''
  }&email=${mappedData.email || ''}&a2=${mappedData.phone || ''}&a1=${mappedData.company || ''}`

  function isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf('calendly') === 0
  }

  const fetchInvitee = async inviteeUrl => {
    try {
      const inviteeResponse = await fetch(inviteeUrl, {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${calendlyAPIKey}`,
        },
      })
      return inviteeResponse
    } catch (err) {
      trackEvent(`API Exception`, {
        label: `Calendly tracking general error`,
        full_exception: err,
      })
      return false
    }
  }

  useEffect(() => {
    window.addEventListener('message', async e => {
      if (isCalendlyEvent(e)) {
        if (e.data.event === 'calendly.event_scheduled') {
          const rawInviteeData = await fetchInvitee(e.data.payload.invitee.uri)
          if (!rawInviteeData.ok) {
            trackEvent(`API Exception`, {
              label: `Calendly tracking general error`,
              full_exception: rawInviteeData,
            })
          }
          const inviteeData = await rawInviteeData.json()
          const companyAnswer = inviteeData.resource.questions_and_answers.find(field => {
            if (field.question === 'Company') return field
            return null
          })
          trackEvent(`Calendly Meeting Scheduled`, {
            name: inviteeData.resource.name || '',
            email: inviteeData.resource.email || '',
            company_name: companyAnswer.answer || '',
            calendly_url: modifiedCalendlyID || '',
          })
        }
      }
    })
  }, [])

  return (
    <Layout showHeader showCTA>
      <Wrapper>
        <Header>
          <Text heading3 role="heading" aria-level="1" center>
            Find some time with our team of accessibility experts to understand your path to compliance
          </Text>
        </Header>
        <ContentWrapper>
          <FormWrapper>
            <div className="calendly-inline-widget" data-url={url} style={{ width: '100%', height: 750 }} />
          </FormWrapper>
        </ContentWrapper>
      </Wrapper>
    </Layout>
  )
}

export default ScheduleMeeting
